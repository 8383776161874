ion-content ion-toolbar {
  --background: translucent;
}

.help {
  width: 380px;
  height: 380px;
  float: left;
  font-size: 15px;
  margin: 5px;
}



.help ion-card-title {
  font-size: 120%;
}

.help table {
  height: 100%;
  width: 100%;
}


.help ion-card {
  padding: 10px;
  height: 100%;
  width: 100%;
}

